import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import Alert from './components/layout/Alert'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import { GithubProvider } from './context/deal-assignments/ECContext'
import { AlertProvider } from './context/alert/AlertContext'

function App() {
  return (

    <GithubProvider>
      <AlertProvider>
        <Router>
          <div className='flex flex-col justify-between '>

{/*            <Navbar />*/}

            {/*<main className='container mx-auto px-3 pb-12'>*/}
            <main className='container mx-auto max-w-fit'>

              {/*<Alert />*/}

              <Routes>
                <Route path='/' element={<Home />} />
{/*
                <Route path='/about' element={<About />} />
                <Route path='/user/:login' element={<User />} />
*/}
                <Route path='/notfound' element={<NotFound />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </main>

{/*            <Footer />*/}

          </div>
        </Router>
      </AlertProvider>
    </GithubProvider>
  )
}

export default App
