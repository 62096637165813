import axios from 'axios'

const GITHUB_URL = process.env.REACT_APP_GITHUB_URL
const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN

const github = axios.create({
  baseURL: GITHUB_URL,
/*  headers: { Authorization: `token ${GITHUB_TOKEN}` },*/
})

// Get search results (deals)
export const searchUsers = async (text, stage, onlyPartlyAssigned = false) => {

  const params = new URLSearchParams({
    q: text,
    stage: stage
  })

  //const response = await github.get(`/search/users?${params}`)

  //crm.deal.list.json?FILTER[>DATE_MODIFY]=05.12.2020 00:00:00&SELECT[]=TITLE, STAGE_ID, ID, UF_*

  // console.log("Text")
  // console.log(text)

  //const response = await github.get(`/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[STAGE_ID]=${stage}`)

  let url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[STAGE_ID]=${stage}`

   // https://estateclaim.bitrix24.com/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[!UF_CRM_1616854241]=1&filter[STAGE_ID]=C2:PREPARATION&filter[%TITLE]=Aydin

  if (text !== "")
    url = url + `&filter[%TITLE]=${text}`

  console.log('url:')
  console.log(url)

  const response = await github.get(url)


  //const response = await github.get(`/rest/4/bcn7t016d5tuehkp/crm.deal.list.json`)
  //const response = await github.get(`/rest/4/bcn7t016d5tuehkp/crm.deal.list.json`)

  //console.log(response.data.result)

  //return response.data.items
  return response.data.result
}

export const getSubContractors = async () => {

  console.log('=== getting subs ====')

  let url = `/rest/4/bcn7t016d5tuehkp/user.search.json?select[]=id&select[]=*&select[]=UF_*&filter[USER_TYPE]=extranet`
  const response = await github.get(url)

  return response.data.result.sort((a,b) => {

    var nameA = a.NAME.toUpperCase(); // ignore upper and lowercase
    var nameB = b.NAME.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  })

}

export const getPhotos = async (dialId) => {


  //task.commentitem.getlist
  //[ATTACHED_OBJECTS]
  //? disk.attachedObject.get
  //[DOWNLOAD_URL]

  //console.log('=== getting PHOTOS ====')

  let url = `/rest/4/bcn7t016d5tuehkp/tasks.task.list?select[]=*&filter[%TITLE]=ProdChkList&filter[UF_CRM_TASK]=D_${dialId}`
  const responseTask = await github.get(url)

  //console.log('======= taskInfo:')
  let taskId = ''
  if(responseTask.data.total > 0) {

    taskId = responseTask.data.result.tasks[0].id
    //console.log(responseTask.data.result.tasks[0].id)

  }
  else {
    return []
  }




  url = `/rest/4/bcn7t016d5tuehkp/task.commentitem.getlist?TASKID=${taskId}`
  const response = await github.get(url)

  // console.log('======= comments Info:')
  // console.log(response)


//  console.log('======= attachments IDs:')

  let fileIDs = []
  response.data.result.forEach((fileInfo) => {

    if(fileInfo.ATTACHED_OBJECTS) {

      const objects = Object.entries(fileInfo.ATTACHED_OBJECTS)

      // console.log("objects:")
      // console.log(objects)

      if (objects.length > 0)
        objects.forEach((data) => {
          fileIDs.push(data[0])
        })

    }
  })

  //console.log(fileIDs)



  //console.log('======= test attachment info:')

  let photoData = []
  for (const fileId of fileIDs) {

    url = `/rest/4/bcn7t016d5tuehkp/disk.attachedObject.get?id=${fileId}`
    const responseAttachment = await github.get(url)

    if(responseAttachment.data.result.NAME.toLowerCase().indexOf(".pdf") !== -1)
      continue

console.log("responseAttachment:")
console.log(responseAttachment)

    let attachemtnInfo = {
      fileId: fileId,
      fileName: responseAttachment.data.result.NAME,
      url: responseAttachment.data.result.DOWNLOAD_URL

    }

    photoData.push(attachemtnInfo)

  }


  // console.log("photoData from the ECActions: +++++++++++++++")
  // console.log(photoData)

  // let testArray = []
  // testArray.push({id: 1})
  // testArray.push({id: 2})

  return photoData
  //return testArray
}

export const getCalendarEvents = async () => {

  /*
      const calendarEvents = [
        { title: 'event 1', date: '2021-12-10' },
        { title: 'event 2', date: '2021-12-11' }
      ]
    */
  const calendarEvents = []

//Acme
  let url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=11&section=37&from=2021-12-01`
  let response = await github.get(url)


  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
         backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Contac Construction LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=15&section=85&from=2021-12-01`
  response = await github.get(url)


  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
         backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//E & S Quality Services LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=17&section=71&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
         backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Exterior Home Renovation LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=23&section=91&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//FS Roofing Construction LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=27&section=93&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Grupo Carso
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=59&section=251&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//jreyexteriors
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=63&section=273&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Luna Roofing
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=35&section=49&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Lund Construction LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=37&section=79&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Mercado Construction LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=39&section=51&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Morelos Roofing LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=41&section=81&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Shakopee Construction LLC
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=45&section=83&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
          backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })

//Wright-Way Seamless Inc
  url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=53&section=97&from=2021-12-01`
  response = await github.get(url)

  response.data.result.forEach( (event) => {
    let fromDate = new Date(event.DATE_FROM)
    fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

    calendarEvents.push(
       {
         title: event.NAME,
         date: fromDate.toISOString().split('T')[0],
         id: event.ID,
         backgroundColor: event.COLOR
         //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
       }
    )
  })


//CameroAble
   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=67&section=307&from=2021-12-01`
   response = await github.get(url)

   response.data.result.forEach( (event) => {
      let fromDate = new Date(event.DATE_FROM)
      fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

      calendarEvents.push(
         {
            title: event.NAME,
            date: fromDate.toISOString().split('T')[0],
            id: event.ID,
            backgroundColor: event.COLOR
            //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
         }
      )
   })

//MBJMConstruction
   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=69&section=315&from=2021-12-01`
   response = await github.get(url)

   response.data.result.forEach( (event) => {
      let fromDate = new Date(event.DATE_FROM)
      fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

      calendarEvents.push(
         {
            title: event.NAME,
            date: fromDate.toISOString().split('T')[0],
            id: event.ID,
            backgroundColor: event.COLOR
            //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
         }
      )
   })

//akaconstruction
   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=73&section=343&from=2021-12-01`
   response = await github.get(url)

   response.data.result.forEach( (event) => {
      let fromDate = new Date(event.DATE_FROM)
      fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

      calendarEvents.push(
         {
            title: event.NAME,
            date: fromDate.toISOString().split('T')[0],
            id: event.ID,
            backgroundColor: event.COLOR
            //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
         }
      )
   })

//Primeelementconstruction
   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=77&section=371&from=2021-12-01`
   response = await github.get(url)

   response.data.result.forEach( (event) => {
      let fromDate = new Date(event.DATE_FROM)
      fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

      calendarEvents.push(
         {
            title: event.NAME,
            date: fromDate.toISOString().split('T')[0],
            id: event.ID,
            backgroundColor: event.COLOR
            //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
         }
      )
   })



// Opendoorrestoration
   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=79&section=375&from=2021-12-01`
   response = await github.get(url)

   response.data.result.forEach( (event) => {
      let fromDate = new Date(event.DATE_FROM)
      fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

      calendarEvents.push(
         {
            title: event.NAME,
            date: fromDate.toISOString().split('T')[0],
            id: event.ID,
            backgroundColor: event.COLOR
            //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
         }
      )
   })

// INDROOFING
   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=83&section=381&from=2021-12-01`
   response = await github.get(url)

   response.data.result.forEach( (event) => {
      let fromDate = new Date(event.DATE_FROM)
      fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

      calendarEvents.push(
         {
            title: event.NAME,
            date: fromDate.toISOString().split('T')[0],
            id: event.ID,
            backgroundColor: event.COLOR
            //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
         }
      )
   })



  console.log('CALENDAR Events:')
   console.log(response.data)
   console.log(calendarEvents)



  return calendarEvents


}

export const getCalendarEvent = async (eventId, eventTitle, event) => {

  let dealId = ""
  let tradeType = []

  let matches = eventTitle.match(/\[(.*?)\]/);
  if (matches) {
    dealId = matches[1];
  }

  //matches = eventTitle.match(/\((.*?)+\)/);
  //matches = eventTitle.match(/\(([^\)]*)\)/);

   //matches = eventTitle.match(/\((.*?)\)/gm);

   matches = eventTitle.match(/\(([A-Z]+)\)/gm); //working

   console.log('MATCHES:')
   console.log(matches)

   matches.forEach((match) => {
      let temp = match.replace("(", "")
      temp = temp.replace(")", "")

      tradeType.push(temp)
   })

   console.log("tradeType")
   console.log(tradeType)



  // console.log(`delId: ${dealId}`)
  // console.log(`tradeType: ${tradeType}`)
  // console.log(`eventId: ${eventId}`)


  let url = `/rest/4/8hwtage786darjve/calendar.event.getbyid.json?id=${eventId}`
  const response = await github.get(url)


   url = `/rest/4/8hwtage786darjve/crm.deal.get.json?id=${dealId}`
   const responseDealData = await github.get(url)



  console.log("Event data:")
  console.log(response)

   console.log("Deal data:")
   console.log(response)




  //const subId = response.data.result.ATTENDEES_CODES[0].replace('U', '')
  const subId = response.data.result.ATTENDEE_LIST[0].id

  // console.log("subId:")
  // console.log(subId)
  //
  // console.log("Event data:")
  // console.log(event)

  return {
    dealId: dealId,
    tradeType: tradeType,
    subId: subId,
    date: event.startStr,
    eventId: eventId,

    dealData:  responseDealData.data.result
  }

}





// Get user and repos
export const getUserAndRepos = async (login) => {
  const [user, repos] = await Promise.all([
    github.get(`/users/${login}`),
    github.get(`/users/${login}/repos`),
  ])

  return { user: user.data, repos: repos.data }
}
