import {useContext, useEffect} from 'react'
import Spinner from '../layout/Spinner'
import DealItem from  '../deals/DealItem'
import ECContext from '../../context/deal-assignments/ECContext'




function isShow(deal, showOnlyPartlyAssigned) {

  const _trades = []

  const crewRBuildDate = deal.UF_CRM_1610705339
  const crewSBuildDate = deal.UF_CRM_1610877006
  const crewWBuildDate = deal.UF_CRM_1610877523
  const crewGBuildDate = deal.UF_CRM_1610877363
  const crewFBuildDate = deal.UF_CRM_1610877224
  const crewDBuildDate = deal.UF_CRM_1610877813
  const crewIBuildDate = deal.UF_CRM_1610877927
  const crewWWBuildDate = deal.UF_CRM_1611841827

  const scopeOfWork = Object.values(deal.UF_CRM_1608388387)

  // if(deal.ID == 9479) {
  //    console.log("SOW:")
  //    console.log(scopeOfWork)
  // }

  let tempBuildDate = ""
  let tempTradeType = ""

  if(crewRBuildDate !== "") {
    tempBuildDate = crewRBuildDate
    tempTradeType = "Roof"

    const trade = {type: "Roof", buildDate: convertDate(crewRBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(44) !== -1) {
    const trade = {type: "Roof", buildDate: ""}
    _trades.push(trade)
  }


  if(crewSBuildDate !== "") {
    tempBuildDate = crewSBuildDate
    tempTradeType = "Siding"

    const trade = {type: "Siding", buildDate: convertDate(crewSBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(50) !== -1) {
    const trade = {type: "Siding", buildDate: ""}
    _trades.push(trade)
  }



  if(crewWBuildDate !== "") {
    tempBuildDate = crewWBuildDate
    tempTradeType = "Windows"

    const trade = {type: "Windows", buildDate: convertDate(crewWBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(52) !== -1) {
    const trade = {type: "Windows", buildDate: ""}
    _trades.push(trade)
  }



  if(crewGBuildDate !== "") {
    tempBuildDate = crewGBuildDate
    tempTradeType = "Gutters"

    const trade = {type: "Gutters", buildDate: convertDate(crewGBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(54) !== -1) {
    const trade = {type: "Gutters", buildDate: ""}
    _trades.push(trade)
  }



  if(crewFBuildDate !== "") {
    tempBuildDate = crewFBuildDate
    tempTradeType = "Fascia"

    const trade = {type: "Fascia", buildDate: convertDate(crewFBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(56) !== -1) {
    const trade = {type: "Fascia", buildDate: ""}
    _trades.push(trade)
  }


  if(crewDBuildDate !== "") {
    tempBuildDate = crewDBuildDate
    tempTradeType = "Deck"

    const trade = {type: "Deck", buildDate: convertDate(crewDBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(58) !== -1) {
    const trade = {type: "Deck", buildDate: ""}
    _trades.push(trade)
  }


  if(crewIBuildDate !== "") {
    tempBuildDate = crewIBuildDate
    tempTradeType = "Interior"

    const trade = {type: "Interior", buildDate: convertDate(crewIBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(60) !== -1) {
    const trade = {type: "Interior", buildDate: ""}
    _trades.push(trade)
  }


  if(crewWWBuildDate !== "") {
    tempBuildDate = crewWWBuildDate
    tempTradeType = "Window Wraps"

    const trade = {type: "Window Wraps", buildDate: convertDate(crewWWBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(319) !== -1) {
    const trade = {type: "Window Wraps", buildDate: ""}
    _trades.push(trade)
  }


  const tradesWithEmptyBuildDates = _trades.filter((trade) => trade.buildDate === "")
  const totalUnasignedTrades =  Object.keys(tradesWithEmptyBuildDates).length

  const tradesWithNoneEmptyBuildDates = _trades.filter((trade) => trade.buildDate !== "")
  const totalAssighenTrades =  Object.keys(tradesWithNoneEmptyBuildDates).length


  let needToShow = false

  if (showOnlyPartlyAssigned === true && totalUnasignedTrades > 0 && totalAssighenTrades > 0)
    needToShow = true
  else if (showOnlyPartlyAssigned === false)
    needToShow = true


  return needToShow;
}

function convertDate(tempBuildDate) {

  if(tempBuildDate !== "") {


    //let x = "2011-01-28T19:30:00EST"
    //let x =   "2021-11-16T09:00:00+03:00"
    let x = tempBuildDate

    let MM = {Jan:"January", Feb:"February", Mar:"March", Apr:"April", May:"May", Jun:"June", Jul:"July", Aug:"August", Sep:"September", Oct:"October", Nov:"November", Dec:"December"}

    let xx = String(new Date(x)).replace(
       /\w{3} (\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2}):[^(]+\(([A-Z]{3})\)/,
       function($0,$1,$2,$3,$4,$5,$6){
         return MM[$1]+" "+$2+", "+$3+" - "+$4%12+":"+$5+(+$4>12?"PM":"AM")+" "+$6
       }
    )
    // console.log("xx:")
    // console.log(xx)

    const formattedDate = new Date(xx)
    // console.log("new date:")
    // console.log(formattedDate.getFullYear())

    // console.log('Title:')
    // console.log(deal.TITLE)

    //tempBuildDate = `${formattedDate.getMonth()}/${formattedDate.getDay()}/${formattedDate.getFullYear()}`
    tempBuildDate = formattedDate.toDateString()

    return tempBuildDate;


    // console.log('Filan data')
    // console.log(tempBuildDate)

  }
}




function DealResults() {
  const { users, loading, showOnlyPartlyAssigned } = useContext(ECContext)

//  console.log(users)

  if (!loading) {
    return (

      <div className='grid grid-cols-1 gap-1' id='deals'>

        {users.map((user) => (


            isShow(user, showOnlyPartlyAssigned) === true
             ? <DealItem key={user.ID} prop={ {deals: {user}, showOnlyPartlyAssigned: {showOnlyPartlyAssigned}} } />
             : ""



        ))}
      </div>

    )
  } else {
    return <Spinner />
  }
}

export default DealResults
