const ECReducer = (state, action) => {
  switch (action.type) {
    case 'GET_USERS':
      return {
        ...state,
        users: action.payload,
        loading: false,
      }
    case 'GET_SUBS':
      return {
        ...state,
        subs: action.payload,
        loading: false,
      }
    case 'GET_PHOTOS':
      return {
        ...state,
        photos: action.payload,
        dialogLoading: false,
      }
    case 'SET_SELECTED_PHOTOS':
      return {
        ...state,
        selectedPhotos: action.payload,
      }
    case 'GET_CALENDAR_EVENTS':
      return {
        ...state,
        calendarEvents: action.payload,
        loadingCalendar: false,
      }



    case 'GET_USER_AND_REPOS':
      return {
        ...state,
        user: action.payload.user,
        repos: action.payload.repos,
        loading: false,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      }
    case 'SET_DIALOG_LOADING':
      return {
        ...state,
        dialogLoading: true,
      }
    case 'CLEAR_USERS':
      return {
        ...state,
        users: [],
      }
    case 'SET_SHOW_DIALOG':
      return {
        ...state,
        showBuildDateDialog: true,
      }
    case 'SET_HIDE_DIALOG':
      return {
        ...state,
        showBuildDateDialog: false,
      }
    case 'SET_DEAL_DATA':
      return {
        ...state,
        deal: action.payload,
      }

    case 'SET_SAVING_DIALOG_IN_PROGRESS':
      return {
        ...state,
        savingDialogInProgress: true,
      }
    case 'DROP_SAVING_DIALOG_IN_PROGRESS':
      return {
        ...state,
        savingDialogInProgress: false,
      }

    case 'GET_CALENDAR_EVENT':
      return {
        ...state,
        calendarEvent: action.payload
      }
    case 'SET_SHOW_UPDATE_DIALOG':
      return {
        ...state,
        showUpdateBuildDateDialog: true
      }
    case 'SET_HIDE_UPDATE_DIALOG':
      return {
        ...state,
        showUpdateBuildDateDialog: false
      }
    case 'SET_PARTLY_ASSIGNED':
      return {
        ...state,
        showOnlyPartlyAssigned: action.payload
      }







    default:
      return state
  }
}

export default ECReducer
