import * as React from 'react';
import axios from 'axios'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import DealItem from "./DealItem";
import {useEffect, useContext} from "react";
import {Draggable} from "@fullcalendar/interaction";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ECContext from '../../context/deal-assignments/ECContext'

import TransferPhotoList from "../buildDateDialog/TransferPhotoList";
import {getPhotos, getSubContractors, getCalendarEvents} from "../../context/deal-assignments/ECActions";
import Spinner from "../layout/Spinner";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { green } from '@mui/material/colors';


function UpdateBuildDateDlg() {
    //const [show, setShow] = React.useState(showDialog);

    const phpServer = axios.create({})

    const { showUpdateBuildDateDialog, calendarEvent, deal, subs, photos, selectedPhotos, dispatch, savingDialogInProgress } = useContext(ECContext)

    const [tradeType, setTradeType] = React.useState([]);
    const [crew, setCrew] = React.useState('');
    const [buildDate, setBuildDate] = React.useState('');
    const [materialColor, setMaterialColor] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [dumpsterLocation, setDumpsterLocation] = React.useState('');


    const [oldProjectComments, setoldProjectComments] = React.useState('');
    const [projectComments, setProjectComments] = React.useState('');
    const [curSelectedPhotos, setCurSelectedPhotos] = React.useState({});


    const [dealData, setDealData] = React.useState({});
    const [subsData, setSubsData] = React.useState([]);

    const handleChangeTradeType = (event) => {
        setTradeType(event.target.value);
    };
    const handleChangeCrew = (event) => {
        setCrew(event.target.value);
    };

    const handleChangeBuildDate = (event) => {
        setBuildDate(event.target.value)
    }

    const handleChangeProjectComments = (event) => {
        setProjectComments(event.target.value)
    }

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(async () => {

       dispatch({ type: 'DROP_SAVING_DIALOG_IN_PROGRESS' })


console.log("calendar data 1:")
console.log(calendarEvent.date)

        setDealData(deal)
        setSubsData(subs)

        setBuildDate(calendarEvent.date)


       let tmpDealData = calendarEvent.dealData

       setDumpsterLocation(tmpDealData.UF_CRM_1616043895752)

       let materialColor = ''
       if(tmpDealData.UF_CRM_1610705431) materialColor += 'R:'+tmpDealData.UF_CRM_1610705431
       if(tmpDealData.UF_CRM_1610877081) materialColor += 'S:'+tmpDealData.UF_CRM_1610877081
       if(tmpDealData.UF_CRM_1610877278) materialColor += 'F:'+tmpDealData.UF_CRM_1610877278
       if(tmpDealData.UF_CRM_1610877396) materialColor += 'G:'+tmpDealData.UF_CRM_1610877396
       if(tmpDealData.UF_CRM_1610877841) materialColor += 'D:'+tmpDealData.UF_CRM_1610877841
       if(tmpDealData.UF_CRM_1610877952) materialColor += 'I:'+tmpDealData.UF_CRM_1610877952
       if(tmpDealData.UF_CRM_1610877952) materialColor += 'WW:'+tmpDealData.UF_CRM_1610877952

       setMaterialColor(materialColor);

       let _notes = ''
       if(tmpDealData.UF_CRM_1610705445) _notes += 'R:'+tmpDealData.UF_CRM_1610705445+' | '
       if(tmpDealData.UF_CRM_1610877094) _notes += 'S:'+tmpDealData.UF_CRM_1610877094+' | '
       if(tmpDealData.UF_CRM_1610877407) _notes += 'F:'+tmpDealData.UF_CRM_1610877407+' | '
       if(tmpDealData.UF_CRM_1610877585) _notes += 'G:'+tmpDealData.UF_CRM_1610877585+' | '
       if(tmpDealData.UF_CRM_1610877850) _notes += 'D:'+tmpDealData.UF_CRM_1610877850+' | '
       if(tmpDealData.UF_CRM_1610877963) _notes += 'I:'+tmpDealData.UF_CRM_1610877963+' | '
       if(tmpDealData.UF_CRM_1611841888) _notes += 'WW:'+tmpDealData.UF_CRM_1611841888+' | '

       setNotes(_notes)



       let tradeTypeCode = [];

       if(calendarEvent.tradeType) {
          if (calendarEvent.tradeType.includes('R')) {
             tradeTypeCode.push(10)
             //setProjectComments(dealData.UF_CRM_1612394485)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394485)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394485)
          }

          if (calendarEvent.tradeType.includes('S')) {
             tradeTypeCode.push(20)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394511)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394511)
          }

          if (calendarEvent.tradeType.includes('W')) {
             tradeTypeCode.push(30)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394556)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394556)
          }

          if (calendarEvent.tradeType.includes('WW')) {
             tradeTypeCode.push(40)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394600)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394600)
          }

          if (calendarEvent.tradeType.includes('G')) {
             tradeTypeCode.push(50)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394542)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394542)
          }

          if (calendarEvent.tradeType.includes('F')) {
             tradeTypeCode.push(60)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394526)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394526)
          }


          if (calendarEvent.tradeType.includes('D')) {
             tradeTypeCode.push(70)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394570)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394570)
          }

          if (calendarEvent.tradeType.includes('I')) {
             tradeTypeCode.push(80)
             setProjectComments(calendarEvent.dealData.UF_CRM_1612394585)
             setoldProjectComments(calendarEvent.dealData.UF_CRM_1612394585)
          }

          if (calendarEvent.tradeType.includes('O')) {
             tradeTypeCode.push(90)
             setProjectComments('')
          }
       }


       console.log('tradeTypeCode:')
       console.log(tradeTypeCode)

       setTradeType(tradeTypeCode)

       setCrew(calendarEvent.subId)


       console.log("calendarEvent:")
       console.log(calendarEvent)


       // console.log('INIT.Trade Type:')
 // console.log(calendarEvent.tradeType)
 //       console.log('calendarEvent.dealData.UF_CRM_1612394485:')
 //       console.log(calendarEvent.dealData)


//       setoldProjectComments("111111")





    }, [showUpdateBuildDateDialog]);


    const handleClickOpen = () => {
        //setShow(true);
        //show = true
    };

    const handleUpdateCalendarEvent = async () => {

       dispatch({ type: 'SET_SAVING_DIALOG_IN_PROGRESS' })

        const data = {
            tradeType: tradeType,
            crew: crew,
            buildDate: buildDate,
            projectComments: projectComments,
            oldProjectComments: oldProjectComments,
            dealId: calendarEvent.dealId,
            eventId: calendarEvent.eventId,

        }


console.log("data to send:")
console.log(data)

//return

        const url = 'https://dev.blueocean24.ru/temp/ec_update_calendar_event.php'
        const response = await fetch(url, {
            method: 'POST',         // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors',        // no-cors, *cors, same-origin
            cache: 'no-cache',      // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',     // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });

        console.log("Response result:")
        console.log(response)



//Refreshing the calendar

        const calendarEvents = await getCalendarEvents()
        dispatch({ type: 'GET_CALENDAR_EVENTS', payload: calendarEvents })


        dispatch({ type: 'DROP_SAVING_DIALOG_IN_PROGRESS' })

        dispatch({ type: 'SET_HIDE_UPDATE_DIALOG' })
    }

    const handleClose = () => {
        dispatch({ type: 'SET_HIDE_UPDATE_DIALOG' })
    };





   const handleDeleteCalendarEvent = async () => {

      const data = {
         // tradeType: tradeType,
         buildDate: buildDate,

         //tradeType: calendarEvent.tradeType,
         tradeType: tradeType,
         subId: calendarEvent.subId,
         dealId: calendarEvent.dealId,
         eventId: calendarEvent.eventId
      }


      console.log("data to delete calendarEvent:")
      console.log(data)

      // console.log("calendar event Data:")
      // console.log(calendarEvent)








      const url = 'https://dev.blueocean24.ru/temp/ec_delete_calendar_event.php'
      const response = await fetch(url, {
         method: 'POST',         // *GET, POST, PUT, DELETE, etc.
         mode: 'no-cors',        // no-cors, *cors, same-origin
         cache: 'no-cache',      // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'include', // include, *same-origin, omit
         headers: {
            'Content-Type': 'application/json'
         },
         redirect: 'follow',     // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: JSON.stringify(data) // body data type must match "Content-Type" header
      });

      console.log("Response [DELETE] result:")
      console.log(response)



//Refreshing the calendar

      const calendarEvents = await getCalendarEvents()
      dispatch({ type: 'GET_CALENDAR_EVENTS', payload: calendarEvents })


      dispatch({ type: 'SET_HIDE_UPDATE_DIALOG' })
   };




   const buttonSx = {
      ...(savingDialogInProgress && {
         bgcolor: green[500],
         '&:hover': {
            bgcolor: green[700],
         },
      }),
   };


   const [success, setSuccess] = React.useState(false);



    return (
        <div>
            <Dialog open={showUpdateBuildDateDialog}
                    onClose={handleClose}
            >

                <DialogTitle>Update Sub Event</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>

                                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                        <InputLabel id="trade-type-label">Trade Type</InputLabel>
                                        <Select
                                           labelId="trade-type-label"
                                           id="trade-type-select"
                                           value={tradeType}
                                           onChange={handleChangeTradeType}
                                           label="Trade Type"
                                           disabled="true"

                                           multiple
                                        >

                                            <MenuItem value={10}>Roof</MenuItem>
                                            <MenuItem value={20}>Siding</MenuItem>
                                            <MenuItem value={30}>Windows</MenuItem>
                                            <MenuItem value={40}>Window Wraps</MenuItem>
                                            <MenuItem value={50}>Gutters</MenuItem>
                                            <MenuItem value={60}>Fascia</MenuItem>
                                            <MenuItem value={70}>Deck</MenuItem>
                                            <MenuItem value={80}>Interior</MenuItem>
                                            <MenuItem value={90}>Other</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl
                                       variant="standard"
                                       sx={{m: 1, minWidth: 120}}
                                       disabled="true"
                                    >
                                    <InputLabel id="crew-label">Crew</InputLabel>
                                    <Select
                                    labelId="crew-label"
                                    id="crew"
                                    value={crew}
                                    onChange={handleChangeCrew}
                                    label="Crew"
                                    >

                                {subsData.map((sub) => (
                                    <MenuItem key={sub.ID} value={sub.ID}>{sub.NAME}</MenuItem>
                                    ))}


                                    {/*<MenuItem value={10}>Crew1</MenuItem>*/}
                                    {/*<MenuItem value={20}>Crew2</MenuItem>*/}
                                    {/*<MenuItem value={30}>Crew3</MenuItem>*/}
                                    {/*<MenuItem value={40}>Crew4</MenuItem>*/}
                                    {/*<MenuItem value={50}>Crew5</MenuItem>*/}
                                    </Select>
                                    </FormControl>

                                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <TextField
                                    margin="dense"
                                    id="buildDate"
                                    label="Build Date"
                                    type="date"
                                    fullWidth
                                    variant="standard"
                                    value={buildDate}/*{deal.buildDate}*/
                                    onChange={handleChangeBuildDate}
                                    />
                                    </FormControl>

                                     <FormControl variant="standard" sx={{minWidth: 450}}>
                                        <TextField
                                           id="projectComments"
                                           label="Project Comments"
                                           variant="standard"
                                           value={projectComments}
                                           onChange={handleChangeProjectComments}
                                        />
                                        <TextField id="materialColor" label="Material Color" variant="standard" disabled={true} value={materialColor} />
                                        <TextField id="notes" label="Notes" variant="standard" disabled={true} value={notes}/>
                                        <TextField id="dumpsterLocation" label="Dumpster Location" variant="standard" disabled={true} value={dumpsterLocation}/>

                                     </FormControl>



                </DialogContent>
                <DialogActions>


                   <Button
                      variant="outlined"
                      color="error"
                      onClick={handleDeleteCalendarEvent}>Delete
                   </Button>


                   {/*<Button onClick={handleUpdateCalendarEvent}>Create</Button>*/}
                   <Box sx={{ m: 1, position: 'relative' }}>
                      <Button
                         variant="contained"
                         sx={buttonSx}
                         disabled={savingDialogInProgress}
                         onClick={handleUpdateCalendarEvent}
                      >
                         Update
                      </Button>
                      {savingDialogInProgress && (
                         <CircularProgress
                            size={24}
                            sx={{
                               color: green[500],
                               position: 'absolute',
                               top: '50%',
                               left: '50%',
                               marginTop: '-12px',
                               marginLeft: '-12px',
                            }}
                         />
                      )}
                   </Box>
                   <Button onClick={handleClose}>Close</Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UpdateBuildDateDlg


