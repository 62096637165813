import { useState, useContext, useEffect } from 'react'
import ECContext from '../../context/deal-assignments/ECContext'
import AlertContext from '../../context/alert/AlertContext'
import { searchUsers } from '../../context/deal-assignments/ECActions'
import { getSubContractors } from '../../context/deal-assignments/ECActions'

import Checkbox from '@mui/material/Checkbox';

function DealSearch() {
  const [text, setText] = useState('')
  const [stage, setStage] = useState('C2:NEW')
  const [onlyPartlyAssigned, setonlyPartlyAssigned] = useState(false)


  const { users, dispatch } = useContext(ECContext)
  const { setAlert } = useContext(AlertContext)

  const handleChange = (e) => setText(e.target.value)

  const handleChangeStage = async (e) => {
    setStage(e.target.value)

    dispatch({ type: 'SET_LOADING' })
    const users = await searchUsers(text, e.target.value)
    dispatch({ type: 'GET_USERS', payload: users })

  }

  useEffect(async () => {
    dispatch({ type: 'SET_LOADING' })

    const users = await searchUsers(text, stage)
    dispatch({ type: 'GET_USERS', payload: users })

    const subs = await getSubContractors()
    dispatch({ type: 'GET_SUBS', payload: subs })
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault()

    // if (text === '') {
    //   setAlert('Please enter something', 'error')
    // } else {
      dispatch({ type: 'SET_LOADING' })
      const users = await searchUsers(text, stage, onlyPartlyAssigned)
      dispatch({ type: 'GET_USERS', payload: users })

      //setText('')
//    }
  }

  const handleChangeOnlyPartlyAssigned = async (event) => {
    setonlyPartlyAssigned([event.target.checked, event.target.checked]);


    console.log("setting up Partly assinged variable with: ")
    console.log(event.target.checked)
    dispatch({ type: 'SET_PARTLY_ASSIGNED', payload: event.target.checked })


    dispatch({ type: 'SET_LOADING' })
    const users = await searchUsers(text, stage, event.target.checked)
    dispatch({ type: 'GET_USERS', payload: users })


  };


  return (
    <div className='grid grid-cols-1 mb-1 gap-1'>
      <div>
        <form onSubmit={handleSubmit}>
          <div className='form-control'>
            <div className='relative'>
              <input
                type='text'
                className='w-full pr-20 bg-gray-200 input text-black'
                placeholder='Search'
                value={text}
                onChange={handleChange}
              />

              <label htmlFor="stages">Stage:</label>
              <select className='w-60'
                      name="cars"
                      id="stages"
                      onChange={handleChangeStage}
              >
                <option value="C2:NEW">New</option>
                <option value="C2:PREPARATION">In Progress</option>
                <option value="C2:EXECUTING">Invoiced</option>
              </select>


              <label htmlFor="stages">Only partly assigned:</label>
              <Checkbox
                value={onlyPartlyAssigned}
                onChange={handleChangeOnlyPartlyAssigned}
              />



              <button
                type='submit'
                className='absolute top-0 right-0 rounded-l-none w-30 btn'
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>

{/*
      {users.length > 0 && (
        <div>
          <button
            onClick={() => dispatch({ type: 'CLEAR_USERS' })}
            className='btn btn-ghost btn-lg'
          >
            Clear
          </button>
        </div>
      )}
*/}

    </div>
  )
}

export default DealSearch
