import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import {useContext, useEffect, useState} from "react";




function DealItem( {prop} ) {

   // console.log("party assigned: ")
   // console.log(deal.showOnlyPartlyAssigned)


   console.log("income PROP:")
   console.log(prop)

   let deal = prop.deals.user


   const showOnlyPartlyAssigned = prop.showOnlyPartlyAssigned.showOnlyPartlyAssigned

   console.log("showOnlyPartlyAssigned:")
   console.log(showOnlyPartlyAssigned)

   const _trades = []

   const crewRBuildDate = deal.UF_CRM_1610705339
   const crewSBuildDate = deal.UF_CRM_1610877006
   const crewWBuildDate = deal.UF_CRM_1610877523
   const crewGBuildDate = deal.UF_CRM_1610877363
   const crewFBuildDate = deal.UF_CRM_1610877224
   const crewDBuildDate = deal.UF_CRM_1610877813
   const crewIBuildDate = deal.UF_CRM_1610877927
   const crewWWBuildDate = deal.UF_CRM_1611841827

   const scopeOfWork = Object.values(deal.UF_CRM_1608388387)

   // if(deal.ID == 9479) {
   //    console.log("SOW:")
   //    console.log(scopeOfWork)
   // }

   let tempBuildDate = ""
   let tempTradeType = ""

   if(crewRBuildDate !== "") {
      tempBuildDate = crewRBuildDate
      tempTradeType = "Roof"

      const trade = {type: "Roof", buildDate: convertDate(crewRBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(44) !== -1) {
      const trade = {type: "Roof", buildDate: ""}
      _trades.push(trade)
   }


   if(crewSBuildDate !== "") {
      tempBuildDate = crewSBuildDate
      tempTradeType = "Siding"

      const trade = {type: "Siding", buildDate: convertDate(crewSBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(50) !== -1) {
      const trade = {type: "Siding", buildDate: ""}
      _trades.push(trade)
   }



   if(crewWBuildDate !== "") {
      tempBuildDate = crewWBuildDate
      tempTradeType = "Windows"

      const trade = {type: "Windows", buildDate: convertDate(crewWBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(52) !== -1) {
      const trade = {type: "Windows", buildDate: ""}
      _trades.push(trade)
   }



   if(crewGBuildDate !== "") {
      tempBuildDate = crewGBuildDate
      tempTradeType = "Gutters"

      const trade = {type: "Gutters", buildDate: convertDate(crewGBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(54) !== -1) {
      const trade = {type: "Gutters", buildDate: ""}
      _trades.push(trade)
   }



   if(crewFBuildDate !== "") {
      tempBuildDate = crewFBuildDate
      tempTradeType = "Fascia"

      const trade = {type: "Fascia", buildDate: convertDate(crewFBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(56) !== -1) {
      const trade = {type: "Fascia", buildDate: ""}
      _trades.push(trade)
   }


   if(crewDBuildDate !== "") {
      tempBuildDate = crewDBuildDate
      tempTradeType = "Deck"

      const trade = {type: "Deck", buildDate: convertDate(crewDBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(58) !== -1) {
      const trade = {type: "Deck", buildDate: ""}
      _trades.push(trade)
   }


   if(crewIBuildDate !== "") {
      tempBuildDate = crewIBuildDate
      tempTradeType = "Interior"

      const trade = {type: "Interior", buildDate: convertDate(crewIBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(60) !== -1) {
      const trade = {type: "Interior", buildDate: ""}
      _trades.push(trade)
   }


   if(crewWWBuildDate !== "") {
      tempBuildDate = crewWWBuildDate
      tempTradeType = "Window Wraps"

      const trade = {type: "Window Wraps", buildDate: convertDate(crewWWBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(319) !== -1) {
      const trade = {type: "Window Wraps", buildDate: ""}
      _trades.push(trade)
   }



   function convertDate(tempBuildDate) {

      if(tempBuildDate !== "") {


         //let x = "2011-01-28T19:30:00EST"
         //let x =   "2021-11-16T09:00:00+03:00"
         let x = tempBuildDate

         let MM = {Jan:"January", Feb:"February", Mar:"March", Apr:"April", May:"May", Jun:"June", Jul:"July", Aug:"August", Sep:"September", Oct:"October", Nov:"November", Dec:"December"}

         let xx = String(new Date(x)).replace(
            /\w{3} (\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2}):[^(]+\(([A-Z]{3})\)/,
            function($0,$1,$2,$3,$4,$5,$6){
               return MM[$1]+" "+$2+", "+$3+" - "+$4%12+":"+$5+(+$4>12?"PM":"AM")+" "+$6
            }
         )
         // console.log("xx:")
         // console.log(xx)

         const formattedDate = new Date(xx)
         // console.log("new date:")
         // console.log(formattedDate.getFullYear())

         // console.log('Title:')
         // console.log(deal.TITLE)

         //tempBuildDate = `${formattedDate.getMonth()}/${formattedDate.getDay()}/${formattedDate.getFullYear()}`
         tempBuildDate = formattedDate.toDateString()

         return tempBuildDate;


         // console.log('Filan data')
         // console.log(tempBuildDate)

      }
   }





   const [buildDate, setBuildDate] = useState(tempBuildDate);
   const [tradeType, settradeType] = useState(tempTradeType);

   const [trades, setTrades] = useState(_trades);


    useEffect(() => {

        var containerEl = document.getElementById(`m_${deal.ID}`)
        new Draggable(containerEl, {
            // eventData: function(eventEl) {
            //     return {
            //         //title: deal.TITLE//eventEl.innerText
            //         title: ""
            //     };
            // }
        });
    }, []);



   const tradesWithEmptyBuildDates = trades.filter((trade) => trade.buildDate === "")
   const totalUnasignedTrades =  Object.keys(tradesWithEmptyBuildDates).length

   let needToShow = false

   if (showOnlyPartlyAssigned === true && totalUnasignedTrades > 0)
      needToShow = true
   else if (showOnlyPartlyAssigned === false)
      needToShow = true









   return (


    <div id={`m_${deal.ID}`} draggable={true} className='card shadow-md compact side bg-base-100' >
      <div className='flex-row items-center space-x-4 card-body'>

        <div>
            <a href={`https://estateclaim.bitrix24.com/crm/deal/details/${deal.ID}/`} target="_blank">
               <h2 className='card-title text-sm'>{deal.TITLE}</h2>
            </a>


           {/*{showOnlyPartlyAssigned === true && (<div>TRUE</div>)}*/}
           {/*partly assigned: <strong>{showOnlyPartlyAssigned}</strong>*/}





            {trades.map((trade) => (

               trade.buildDate === ""
                  ? <div className=''>{trade.type}</div>
                  : <div className='bg-green-200'>{trade.buildDate} {tradeType !== "" && <i>, {trade.type}</i>}</div>

            ))}


        </div>

      </div>
    </div>

  )
}

DealItem.propTypes = {
  deal: PropTypes.object.isRequired,
}

export default DealItem
