import { createContext, useReducer } from 'react'
import ECReducer from './ECReducer'

const ECContext = createContext()

export const GithubProvider = ({ children }) => {
  const initialState = {
    users: [],
    subs: [],
    photos: [],
    deal: {},
    repos: [],
    loading: false,
    dialogLoading: false,
    showBuildDateDialog: false,
    selectedPhotos: {},

    loadingCalendar: false,
    calendarEvents: [],

    savingDialogInProgress: false,

    showUpdateBuildDateDialog: false,
    calendarEvent: {},

    showOnlyPartlyAssigned: false
  }

  const [state, dispatch] = useReducer(ECReducer, initialState)

  return (
    <ECContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </ECContext.Provider>
  )
}

export default ECContext
